/* NavBar.module.css */
.navbar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  padding: 1rem;
  box-shadow: 1rem 1rem -1rem rgba(0, 0, 0, 0.2);
  position: fixed;
  left: 0;
  top: 0;
  width: 15rem;
  transition: width 0.3s;
  z-index: 10;
}

.navbar.minimized {
  width: 3rem;
}

.navbarHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}

.logo {
  min-height: 3rem;
  min-width: 1px;
  box-sizing: border-box;
  padding: 0;
}

.logo a {
  display: block;
  height: 3rem;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.logo img {
  height: 3rem;
}

.user {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: center;
  color: inherit;
  cursor: auto;
}

.userTab {
  margin-top: 1rem;
  width: 100%;
}

.userInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-inline: 16px;
}

.userInfo img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.menu {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
}

.menuItem {
  position: relative;
}

.menuItem .submenu {
  display: none;
  position: absolute;
  left: 0;
  top: 100%;
  padding-left: 20px;
  margin-top: 0;
  background: #000;
  border: none;
  width: max-content;
}

.menuItem:hover .submenu {
  display: block;
}

.menu li,
.submenu li {
  width: 100%;
}

.menu a,
.submenu a {
  text-decoration: none;
  font-size: 1.5rem;
  color: white;
  transition: color 0.3s;
  display: flex;
  align-items: left;
  padding: 10px 0;
}

.menu a {
  box-shadow: 1rem 0 1rem -1rem rgba(0, 0, 0, 0.1);
  height: 3rem;
}

.submenu a {
  padding: 8px 20px;
}

.menu a:hover,
.submenu a:hover {
  color: cyan;
}

.menu li,
.submenu li {
  width: 100%;
  margin-bottom: 1rem;
}

.menu a svg {
  margin-right: 1rem;
  font-size: 1.8rem;
}
