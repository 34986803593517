.rainbow-button {
  border: 3px solid transparent;
  background-color: transparent;
  border-image-source: linear-gradient(90deg, red, orange, yellow, green, blue, indigo, violet);
  border-image-slice: 1;
  color: white;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.rainbow-button:hover {
  color: black;
  background-color: white;
}

.rainbow-button::before {
  content: '';
  position: absolute;
  top: -3px;
  left: -3px;
  right: -3px;
  bottom: -3px;
  border: 3px solid transparent;
  border-image-source: linear-gradient(90deg, red, orange, yellow, green, blue, indigo, violet);
  border-image-slice: 1;
  z-index: 0;
  pointer-events: none; /* Ensure the pseudo-element doesn't interfere with button clicks */
}

.rainbow-button span {
  position: relative;
  z-index: 1;
}

