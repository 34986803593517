.login-container {
  display: flex;
  width: 100%;
  min-height: 100vh;
}

.logo-container {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000;
}

.logo-container img {
  object-fit: contain;
  width: 50%; /* you can adjust as needed */
  height: 100%; /* maintain aspect ratio */
}

.form-container {
  width: 100%;
  padding: 20px;
}

.field-container {
  display: flex;
  justify-content: space-between;
}

.field-container > * {
  flex: 1;
  margin: 0 10px;  /* Add some margin to separate the fields */
}

.field-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  gap: 12px;
}

.field {
  flex: 1;
  margin-right: 5px;
}

.field:last-child {
  margin-right: 0;
}

.submit-button {
  display: block;
  margin-top: 20px;
}

